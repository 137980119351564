@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

@media only screen and (min-width: 450px) {
  .pane__2Vwg7 {
    width: 400px;
    margin: 64px auto 112px;
  }
}

@media only screen and (max-width: 449px) {
  .pane__2Vwg7 {
    margin-top: calc(29.8666666667vw);
    padding: 0 calc(8.5333333333vw);
  }
}

.header__2t2K8 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .header__2t2K8 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .header__2t2K8 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .header__2t2K8 {
    font-size: calc(5.3333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

.subheader__2LqnP {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  text-align: center;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .subheader__2LqnP {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .subheader__2LqnP {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .subheader__2LqnP {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .subheader__2LqnP {
    margin: 64px 0;
  }
}

@media only screen and (max-width: 449px) {
  .subheader__2LqnP {
    margin: calc(17.0666666667vw) calc(0vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__2EcgT {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .body__2EcgT {
    margin-top: calc(8.5333333333vw);
  }
}

.splitter__3ChAw {
  border: 1px solid #f3f5f5;
}

@media only screen and (min-width: 450px) {
  .splitter__3ChAw {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .splitter__3ChAw {
    margin-top: calc(10.6666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .form__3vR1j {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 449px) {
  .form__3vR1j {
    margin-top: calc(10.6666666667vw);
  }
}

.button__21eGp {
  width: 100%;
}

@media only screen and (min-width: 450px) {
  .button-wrapper__1c3b6 {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__1c3b6 {
    margin-top: calc(8.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .new-user-cta__2gSU4 {
    margin-top: 58px;
  }
}

@media only screen and (max-width: 449px) {
  .new-user-cta__2gSU4 {
    margin-top: calc(15.4666666667vw);
  }
}

.link__3vUpl {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  text-align: center;
  font-weight: bold;
  color: #4c6072;
}

@media only screen and (min-width: 1200px) {
  .link__3vUpl {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .link__3vUpl {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media only screen and (max-width: 449px) {
  .link__3vUpl {
    font-size: calc(3.7333333333vw);
    line-height: calc(5.2266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .link__3vUpl {
    margin: 32px 0 48px 0;
  }
}

@media only screen and (max-width: 449px) {
  .link__3vUpl {
    margin: calc(8.5333333333vw) calc(0vw) calc(12.8vw) calc(0vw);
  }
}

@media only screen and (min-width: 450px) {
  .notification-wrapper__3Vj9e {
    height: 56px;
    margin: 32px 0 48px 0;
  }
}

@media only screen and (max-width: 449px) {
  .notification-wrapper__3Vj9e {
    height: calc(14.9333333333vw);
    margin: calc(8.5333333333vw) calc(0vw) calc(12.8vw) calc(0vw);
  }
}

.notification__1WiW8 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  text-align: center;
  font-weight: bold;
  color: #5ab364;
}

@media only screen and (min-width: 1200px) {
  .notification__1WiW8 {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .notification__1WiW8 {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media only screen and (max-width: 449px) {
  .notification__1WiW8 {
    font-size: calc(3.7333333333vw);
    line-height: calc(5.2266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .notification__1WiW8 {
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .notification__1WiW8 {
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.form-error__3xN9s {
  text-align: center;
}

