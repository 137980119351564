@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.header__2GTlp {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  text-align: center;
  font-weight: bold;
  margin-top: 56px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1200px) {
  .header__2GTlp {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .header__2GTlp {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .header__2GTlp {
    font-size: calc(5.3333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (max-width: 449px) {
  .header__2GTlp {
    margin-top: calc(14.9333333333vw);
    margin-bottom: calc(8.5333333333vw);
  }
}

.body__6deXW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.application-card__hMWu3 {
  background-color: #fbfbfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 8px;
  width: 327px;
  height: 308px;
  margin: 0 24px 24px 24px;
}

@media only screen and (max-width: 449px) {
  .application-card__hMWu3 {
    border-radius: calc(2.1333333333vw);
    width: calc(87.2vw);
    height: calc(82.1333333333vw);
    margin: 0 0 calc(6.4vw) 0;
  }
}

.card-header__2IbHq {
  height: 160px;
  border-radius: 8px 8px 0 0;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (max-width: 449px) {
  .card-header__2IbHq {
    height: calc(42.6666666667vw);
    border-radius: calc(2.1333333333vw) calc(2.1333333333vw) 0 0;
  }
}

.likes-logo__38CeS {
  width: 120px;
}

@media only screen and (max-width: 449px) {
  .likes-logo__38CeS {
    width: calc(32vw);
  }
}

.beauty-logo__JfB5i {
  fill: #204a88;
  width: 130px;
}

@media only screen and (max-width: 449px) {
  .beauty-logo__JfB5i {
    width: calc(34.6666666667vw);
  }
}

.money-logo__P58d- {
  fill: #ffffff;
  width: 130px;
}

@media only screen and (max-width: 449px) {
  .money-logo__P58d- {
    width: calc(34.6666666667vw);
  }
}

.likes-bg__1QukI {
  background-image: url(/packs/likes_bg-c36155352ab52872ae7732dc07275097.png);
}

.beauty-bg__25GPi {
  background-image: url(/packs/beauty_bg-ae89bdc279795c44d2a599881b851369.png);
}

.money-bg__3EF43 {
  background-image: url(/packs/money_bg-1a35357f3805d802bed0886d857c6b8e.png);
}

.info-text__3VyRt {
  font-weight: bold;
  color: #4c6072;
  max-width: 236px;
  margin-bottom: 12px;
}

@media only screen and (min-width: 1200px) {
  .info-text__3VyRt {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .info-text__3VyRt {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .info-text__3VyRt {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (max-width: 449px) {
  .info-text__3VyRt {
    margin-bottom: calc(3.2vw);
  }
}

.card-body__1kViQ {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.application-cta__2E5Jl {
  width: 240px;
}

@media only screen and (max-width: 449px) {
  .application-cta__2E5Jl {
    width: calc(64vw);
  }
}

