@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.select-wrapper__3Gnfa {
  border-top: 1px solid #f3f5f5;
}

@media only screen and (min-width: 450px) {
  .select-wrapper__3Gnfa {
    padding: 32px 60px 40px;
  }
}

@media only screen and (max-width: 449px) {
  .select-wrapper__3Gnfa {
    padding: calc(7.4666666667vw) calc(6.4vw) calc(8.5333333333vw);
  }
}

.label__C_jzg {
  font-weight: bold;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .label__C_jzg {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .label__C_jzg {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__C_jzg {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .body__7SWhp {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .body__7SWhp {
    margin-top: calc(6.4vw);
  }
}

.select__28Iaq {
  width: 100%;
}

@media only screen and (min-width: 450px) {
  .top-k-select-question-card__body {
    margin-top: 32px;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 449px) {
  .top-k-select-question-card__body {
    margin-top: calc(8.5333333333vw);
    padding: 0 !important;
  }
}

