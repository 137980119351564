@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.pane__36lsY {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 450px) {
  .button-wrapper__bsf71 {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__bsf71 {
    margin-top: calc(8.5333333333vw);
  }
}

@media only screen and (min-width: 450px) {
  .button__7INP2 {
    width: 240px;
  }
}

.header__2zDE_ {
  text-align: center;
}

@media only screen and (min-width: 450px) {
  .header__2zDE_ {
    margin: 40px 0 25px;
  }
}

@media only screen and (max-width: 449px) {
  .header__2zDE_ {
    margin: calc(10.6666666667vw) 0 calc(6.6666666667vw);
  }
}

.title__1Mz0h {
  color: #1f2c32;
  font-weight: bold;
  font-size: 36px;
  line-height: 20px;
  letter-spacing: 4.6px;
}

@media only screen and (min-width: 450px) {
  .title__1Mz0h {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .title__1Mz0h {
    margin-bottom: calc(8.5333333333vw);
  }
}

.text__3dSNX {
  color: #1f2c32;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .text__3dSNX {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .text__3dSNX {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .text__3dSNX {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .text__3dSNX {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 449px) {
  .text__3dSNX {
    margin-bottom: calc(1.3333333333vw);
  }
}

@media only screen and (min-width: 1200px) {
  .name__1P2AK {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .name__1P2AK {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .name__1P2AK {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.body__3c5B7 {
  background-image: url(/packs/attendance_bg-063c7a43ce22755fbe6b57044bf874e7.png);
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 450px) {
  .body__3c5B7 {
    padding: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .body__3c5B7 {
    padding: calc(6.4vw);
  }
}

.reservation_count__2jM14 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-weight: bold;
  color: #1f2c32;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .reservation_count__2jM14 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .reservation_count__2jM14 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .reservation_count__2jM14 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .reservation_count__2jM14 {
    padding: 26px 0 14px;
  }
}

@media only screen and (max-width: 449px) {
  .reservation_count__2jM14 {
    padding: calc(6.9333333333vw) 0 calc(3.7333333333vw);
  }
}

.no__kANzE {
  font-family: europa, sans-serif;
}

@media only screen and (min-width: 450px) {
  .no__kANzE {
    font-size: 20px;
    margin: 0 4px;
  }
}

@media only screen and (max-width: 449px) {
  .no__kANzE {
    font-size: calc(5.3333333333vw);
  }
}

