@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.degree-select-input__366My {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 450px) {
  .degree-select-input__366My {
    margin-right: -8px;
  }
}

@media only screen and (max-width: 449px) {
  .degree-select-input__366My {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: calc(87.2vw);
    margin-top: calc(-3.2vw);
    margin-right: calc(-3.2vw);
  }
}

.circle__2hYxY {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-weight: bold;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 50%;
  color: #d3d4d8;
  border: 2px solid #dadbdd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .circle__2hYxY {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .circle__2hYxY {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .circle__2hYxY {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.circle__2hYxY.checked__1WiCl, .circle__2hYxY:hover {
  background: #4c6072;
  border: 2px solid #4c6072;
  color: white;
}

@media only screen and (min-width: 450px) {
  .circle__2hYxY {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 43px;
            flex: 0 0 43px;
    width: 43px;
    height: 43px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .circle__2hYxY {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(11.4666666667vw);
            flex: 0 0 calc(11.4666666667vw);
    width: calc(11.4666666667vw);
    height: calc(11.4666666667vw);
    margin-right: calc(3.2vw);
    margin-top: calc(3.2vw);
  }
}

