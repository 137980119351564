@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

@media only screen and (min-width: 1200px) {
  .pane__FjbwH {
    width: 100%;
    max-width: 960px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .pane__FjbwH {
    width: 100%;
    padding: 62px 32px;
  }
}

@media only screen and (max-width: 449px) {
  .pane__FjbwH {
    width: 100%;
    padding: 0 min(8.5333333333vw, 32px) min(34.1333333333vw, 128px) min(8.5333333333vw, 32px);
  }
}

.pane-main__3eqRU {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media only screen and (min-width: 1200px) {
  .pane-main__3eqRU {
    padding: 40px;
  }
}

.pane-header__21S4Q {
  color: #1f2d32;
  padding-bottom: 24px;
  border-bottom: 1px solid #edeeef;
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 449px) {
  .pane-header__21S4Q {
    padding: calc(8.5333333333vw) 0 calc(4.2666666667vw);
    border-bottom: 1px solid #edeeef;
    margin-bottom: calc(6.4vw);
  }
}

.pane-sub-header__2U-U7 {
  padding-bottom: 16px;
  border-bottom: 1px solid #edeeef;
  margin-top: 32px;
}

.back-button__fdHqG {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.back-button__fdHqG .icon__30uR9 {
  margin-right: 10px;
}

