@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.string-input__1GIj7 {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #f3f5f5;
  border: 1px solid transparent;
  outline: none;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .string-input__1GIj7 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .string-input__1GIj7 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7 {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.string-input__1GIj7::-webkit-input-placeholder {
  color: #afb0b5;
}

.string-input__1GIj7::-ms-input-placeholder {
  color: #afb0b5;
}

.string-input__1GIj7::placeholder {
  color: #afb0b5;
}

@media only screen and (min-width: 1200px) {
  .string-input__1GIj7::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .string-input__1GIj7::-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .string-input__1GIj7::placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .string-input__1GIj7::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .string-input__1GIj7::-ms-input-placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
  .string-input__1GIj7::placeholder {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7::-webkit-input-placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .string-input__1GIj7::-ms-input-placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
  .string-input__1GIj7::placeholder {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.string-input__1GIj7:focus {
  background: #ffffff;
  border: 1px solid #3c676e;
}

.string-input__1GIj7:hover {
  border: 1px solid #3c676e;
}

.string-input__1GIj7.error__3HP5e {
  border: 1px solid #df5475;
}

.string-input__1GIj7:not(.multiline__1Do1o) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 450px) {
  .string-input__1GIj7:not(.multiline__1Do1o).m__13N4X {
    height: 48px;
    border-radius: 8px;
    padding: 0 16px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7:not(.multiline__1Do1o).m__13N4X {
    height: calc(12.8vw);
    border-radius: calc(2.1333333333vw);
    padding: 0 calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .string-input__1GIj7:not(.multiline__1Do1o).l__DwV5Y {
    height: 72px;
    border-radius: 8px;
    padding: 0 16px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7:not(.multiline__1Do1o).l__DwV5Y {
    height: calc(14.9333333333vw);
    border-radius: calc(2.1333333333vw);
    padding: 0 calc(4.2666666667vw);
  }
}

.string-input__1GIj7.multiline__1Do1o {
  resize: none;
}

@media only screen and (min-width: 450px) {
  .string-input__1GIj7.multiline__1Do1o.m__13N4X {
    height: 48px;
    border-radius: 8px;
    padding: 12px 16px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7.multiline__1Do1o.m__13N4X {
    height: calc(12.8vw);
    border-radius: calc(2.1333333333vw);
    padding: calc(3.2vw) calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .string-input__1GIj7.multiline__1Do1o.l__DwV5Y {
    height: 72px;
    border-radius: 8px;
    padding: 24px 16px;
  }
}

@media only screen and (max-width: 449px) {
  .string-input__1GIj7.multiline__1Do1o.l__DwV5Y {
    height: calc(14.9333333333vw);
    border-radius: calc(2.1333333333vw);
    padding: calc(4.2666666667vw) calc(4.2666666667vw);
  }
}

.string-preview__3E8hH {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .string-preview__3E8hH {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .string-preview__3E8hH {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .string-preview__3E8hH {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .string-preview__3E8hH.m__13N4X {
    min-height: 48px;
    border-radius: 8px;
    padding: 12px 0;
  }
}

@media only screen and (max-width: 449px) {
  .string-preview__3E8hH.m__13N4X {
    min-height: calc(12.8vw);
    border-radius: calc(2.1333333333vw);
    padding: calc(3.2vw) 0;
  }
}

@media only screen and (min-width: 450px) {
  .string-preview__3E8hH.l__DwV5Y {
    min-height: 72px;
    border-radius: 8px;
    padding: 24px 0;
  }
}

@media only screen and (max-width: 449px) {
  .string-preview__3E8hH.l__DwV5Y {
    min-height: calc(14.9333333333vw);
    border-radius: calc(2.1333333333vw);
    padding: calc(4.2666666667vw) 0;
  }
}

