@charset "UTF-8";

.not-found-page__2u_FA {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

@media only screen and (min-width: 450px) {
  .not-found-page__2u_FA {
    padding: 0 56px;
  }
}

.message-box__3PWvr {
  border: 2px solid #7c4751;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: 2px;
  color: #191921;
}

@media only screen and (max-width: 769px) {
  .message-box__3PWvr {
    font-size: 13px;
    line-height: 2;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 769px) {
  .message-box__3PWvr {
    width: 400px;
    padding: 20px;
    margin: 100px auto;
  }
}

@media only screen and (max-width: 769px) {
  .message-box__3PWvr {
    margin: calc(31.25vw) calc(6.25vw);
    padding: calc(6.25vw);
  }
}

.go-home-link__lOjov {
  color: #4c6072;
}

