@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.action-area__3obqE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

@media only screen and (max-width: 449px) {
  .action-area__3obqE {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: min(6.4vw, 24px);
    gap: min(4.2666666667vw, 16px);
  }
}

.subscription-summary__3gdZk {
  border: 1px solid #edeeef;
}

@media only screen and (min-width: 1200px) {
  .subscription-summary__3gdZk:not(:first-child) {
    margin-bottom: 32px;
  }
}

@media only screen and (min-width: 450px) {
  .subscription-summary__3gdZk:not(:first-child) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 449px) {
  .subscription-summary__3gdZk:not(:first-child) {
    margin-top: calc(8.5333333333vw);
    margin-bottom: calc(8.5333333333vw);
  }
}

.subscription-summary-row__1CiwA {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  color: #1f2d32;
}

.subscription-summary-row__1CiwA:not(:last-child) {
  border-bottom: 1px solid #edeeef;
}

@media only screen and (min-width: 1200px) {
  .subscription-summary-row__1CiwA {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .subscription-summary-row__1CiwA {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.subscription-summary-row__1CiwA .title__20HK0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f6f7f7;
}

@media only screen and (min-width: 1200px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    width: 135px;
    -ms-flex-preferred-size: 135px;
        flex-basis: 135px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    width: 135px;
    -ms-flex-preferred-size: 135px;
        flex-basis: 135px;
  }
}

@media only screen and (min-width: 450px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    padding: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .subscription-summary-row__1CiwA .title__20HK0 {
    padding: calc(4.2666666667vw);
  }
}

.subscription-summary-row__1CiwA .body__3q6sQ {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

@media only screen and (min-width: 1200px) {
  .subscription-summary-row__1CiwA .body__3q6sQ {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .subscription-summary-row__1CiwA .body__3q6sQ {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .subscription-summary-row__1CiwA .body__3q6sQ {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .subscription-summary-row__1CiwA .body__3q6sQ {
    padding: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .subscription-summary-row__1CiwA .body__3q6sQ {
    padding: calc(4.2666666667vw);
  }
}

.update-schedule__294of {
  width: 100%;
}

.button-wrapper__2A-O6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media only screen and (min-width: 450px) {
  .button-wrapper__2A-O6 {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .button-wrapper__2A-O6 {
    margin-top: calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .action-button__3T4cN {
    width: 163px;
  }
}

@media only screen and (max-width: 449px) {
  .action-button__3T4cN {
    margin-top: calc(3.2vw);
  }
}

.current-plan__3dr0j {
  width: 100%;
}

@media only screen and (min-width: 450px) {
  .current-plan__3dr0j {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

@media only screen and (min-width: 450px) {
  .suspension-button__EpO65 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    padding-top: 16px;
    float: right;
  }
}

.cancel-future-suspension__3r8e8 {
  text-align: right;
}

@media only screen and (min-width: 450px) {
  .cancel-future-suspension__3r8e8 {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .cancel-future-suspension__3r8e8 {
    margin-top: min(4.2666666667vw, 16px);
  }
}

.cancel-future-suspension-button__L5KD2 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media only screen and (min-width: 450px) {
  .cancel-future-suspension-button__L5KD2 {
    float: right;
  }
}

@media only screen and (max-width: 449px) {
  .cancel-future-suspension-button__L5KD2 {
    margin: 0 auto;
  }
}

.suspension-period__1xe72 {
  font-weight: bold;
}

.suspension-status__3Eo-3 {
  background-color: #f6f7f7;
  display: inline-block;
  font-size: 10px;
  padding: 2px 8px;
}

.attention__2l3i1 {
  color: var(--color-text-lightest);
}

@media only screen and (min-width: 450px) {
  .plan-warning__2r2il {
    max-width: 420px;
    margin-top: 12px;
  }
}

@media only screen and (max-width: 449px) {
  .plan-warning__2r2il {
    margin-top: calc(3.2vw);
  }
}

@media only screen and (max-width: 449px) {
  .plan-request-warning__2ZQi7 {
    margin-bottom: calc(17.0666666667vw);
  }
}

.red__3fLpr {
  color: #df5475;
}

.expires-at-date__2-yzY {
  color: #849194;
}

