@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.loader-wrapper__JAW_m {
  margin: auto;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
}

.loader-wrapper__JAW_m.exiting__2fHAx, .loader-wrapper__JAW_m.exited__39_EM {
  opacity: 0;
}

.loader-wrapper__JAW_m.entering__1ti5z, .loader-wrapper__JAW_m.entered__2xR3X {
  opacity: 1;
}

.loader__3kQ4o {
  width: 50px;
  height: auto;
}

.loader__3kQ4o path {
  fill: white;
}

