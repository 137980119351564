@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

@media only screen and (min-width: 450px) {
  .container__bIQ3A {
    margin-top: -16px;
  }
}

@media only screen and (max-width: 449px) {
  .container__bIQ3A {
    margin-top: calc(-4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .container__bIQ3A.s__1Avwa {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-right: -16px;
    margin-top: -16px;
  }
}

@media only screen and (min-width: 450px) {
  .option__7Jyq_ {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .option__7Jyq_ {
    margin-top: calc(4.2666666667vw);
  }
}

@media only screen and (min-width: 1200px) {
  .option__7Jyq_.s__1Avwa {
    margin-right: 16px;
    margin-top: 16px;
    width: 246px;
  }
}

