@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.page__1WVW1 {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
}

.page-back-button__IGgE_ {
  max-width: 800px;
  margin: 0 auto 24px auto;
}

@media screen and (max-width: 1012px) {
  .page-back-button__IGgE_ {
    margin: 24px auto 24px 24px;
  }
}

.page-for-my-page-renewal__2qe4q {
  max-width: 1021px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
}

@media only screen and (min-width: 450px) {
  .page-for-my-page-renewal__2qe4q {
    padding: 0px 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .pane__2jtJo {
    margin-top: 40px;
    padding: 56px 0;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .pane__2jtJo {
    margin-top: 20px;
    padding: 60px 24px;
  }
}

@media only screen and (max-width: 449px) {
  .pane__2jtJo {
    margin-top: calc(5.3333333333vw);
    padding: calc(16vw) calc(6.4vw);
  }
}

.pane-header__UpZY2 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  line-height: 1;
}

@media only screen and (min-width: 1200px) and (min-width: 1200px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1200px) and (min-width: 450px) and (max-width: 1199px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 449px) {
  .pane-header__UpZY2 {
    font-size: calc(6.4vw);
    line-height: calc(8.5333333333vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .pane-header__UpZY2 {
    padding: 23px 0;
    border-bottom: 1px solid #d3d4d8;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) and (min-width: 1200px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) and (min-width: 450px) and (max-width: 1199px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) and (max-width: 449px) {
  .pane-header__UpZY2 {
    font-size: calc(6.4vw);
    line-height: calc(8.5333333333vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (max-width: 449px) {
  .pane-header__UpZY2 {
    padding: calc(6.1333333333vw) 0;
    border-bottom: 1px solid #d3d4d8;
  }
}

@media only screen and (max-width: 449px) and (min-width: 1200px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) and (min-width: 450px) and (max-width: 1199px) {
  .pane-header__UpZY2 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) and (max-width: 449px) {
  .pane-header__UpZY2 {
    font-size: calc(6.4vw);
    line-height: calc(8.5333333333vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

.pane-header-title__3vUJC {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  margin-top: 40px;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .pane-header-title__3vUJC {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .pane-header-title__3vUJC {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .pane-header-title__3vUJC {
    font-size: calc(6.4vw);
    line-height: calc(8.5333333333vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

@media only screen and (min-width: 1200px) {
  .pane-body__22dOL {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .pane-body__22dOL {
    margin-top: 36px;
  }
}

@media only screen and (max-width: 449px) {
  .pane-body__22dOL {
    margin-top: calc(9.6vw);
  }
}

.back-button__OSBU8 {
  padding: 0;
  border: none;
  width: 100%;
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .back-button__OSBU8 {
    padding: 0 24px;
  }
}

@media only screen and (max-width: 449px) {
  .back-button__OSBU8 {
    padding: 0 calc(6.4vw);
  }
}

