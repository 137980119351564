@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.satisfaction-circle__2Gk_B {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
}

.satisfaction-circle__2Gk_B.level-1__NhsWF {
  border: 2px solid #6b9aaa;
}

@media only screen and (min-width: 450px) {
  .satisfaction-circle__2Gk_B.level-1__NhsWF {
    width: 53px;
    height: 53px;
  }
}

@media only screen and (max-width: 449px) {
  .satisfaction-circle__2Gk_B.level-1__NhsWF {
    width: calc(14.1333333333vw);
    height: calc(14.1333333333vw);
  }
}

.satisfaction-circle__2Gk_B.level-1__NhsWF.checked__hMxAq, .satisfaction-circle__2Gk_B.level-1__NhsWF:hover {
  background: #6b9aaa;
}

.satisfaction-circle__2Gk_B.level-2__2OU2e {
  border: 2px solid #b9d1db;
}

@media only screen and (min-width: 450px) {
  .satisfaction-circle__2Gk_B.level-2__2OU2e {
    width: 43px;
    height: 43px;
  }
}

@media only screen and (max-width: 449px) {
  .satisfaction-circle__2Gk_B.level-2__2OU2e {
    width: calc(11.4666666667vw);
    height: calc(11.4666666667vw);
  }
}

.satisfaction-circle__2Gk_B.level-2__2OU2e.checked__hMxAq, .satisfaction-circle__2Gk_B.level-2__2OU2e:hover {
  background: #b9d1db;
}

.satisfaction-circle__2Gk_B.level-3__1ZRlf {
  border: 2px solid #afb0b5;
}

@media only screen and (min-width: 450px) {
  .satisfaction-circle__2Gk_B.level-3__1ZRlf {
    width: 33px;
    height: 33px;
  }
}

@media only screen and (max-width: 449px) {
  .satisfaction-circle__2Gk_B.level-3__1ZRlf {
    width: calc(8.8vw);
    height: calc(8.8vw);
  }
}

.satisfaction-circle__2Gk_B.level-3__1ZRlf.checked__hMxAq, .satisfaction-circle__2Gk_B.level-3__1ZRlf:hover {
  background: #afb0b5;
}

.satisfaction-circle__2Gk_B.level-4__NOiCy {
  border: 2px solid #eed4d3;
}

@media only screen and (min-width: 450px) {
  .satisfaction-circle__2Gk_B.level-4__NOiCy {
    width: 43px;
    height: 43px;
  }
}

@media only screen and (max-width: 449px) {
  .satisfaction-circle__2Gk_B.level-4__NOiCy {
    width: calc(11.4666666667vw);
    height: calc(11.4666666667vw);
  }
}

.satisfaction-circle__2Gk_B.level-4__NOiCy.checked__hMxAq, .satisfaction-circle__2Gk_B.level-4__NOiCy:hover {
  background: #eed4d3;
}

.satisfaction-circle__2Gk_B.level-5__1twgt {
  border: 2px solid #d6a0a0;
}

@media only screen and (min-width: 450px) {
  .satisfaction-circle__2Gk_B.level-5__1twgt {
    width: 53px;
    height: 53px;
  }
}

@media only screen and (max-width: 449px) {
  .satisfaction-circle__2Gk_B.level-5__1twgt {
    width: calc(14.1333333333vw);
    height: calc(14.1333333333vw);
  }
}

.satisfaction-circle__2Gk_B.level-5__1twgt.checked__hMxAq, .satisfaction-circle__2Gk_B.level-5__1twgt:hover {
  background: #d6a0a0;
}

.satisfaction-circle__2Gk_B:not(.checked__hMxAq):not(:hover) .check__22P7n {
  display: none;
}

.check__22P7n {
  fill: white;
}

.option__3ugLY {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media only screen and (max-width: 449px) {
  .option__3ugLY {
    max-width: calc(16vw);
  }
}

.option__3ugLY.level-1__NhsWF {
  color: #6b9aaa;
}

.option__3ugLY.level-2__2OU2e {
  color: #b9d1db;
}

.option__3ugLY.level-3__1ZRlf {
  color: #afb0b5;
}

.option__3ugLY.level-4__NOiCy {
  color: #eed4d3;
}

.option__3ugLY.level-5__1twgt {
  color: #d6a0a0;
}

.option-body__DaV7n {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media only screen and (min-width: 450px) {
  .option-body__DaV7n {
    height: 53px;
    width: 53px;
  }
}

@media only screen and (max-width: 449px) {
  .option-body__DaV7n {
    height: calc(14.1333333333vw);
    width: calc(14.1333333333vw);
  }
}

.label__1wkK6 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-weight: bold;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .label__1wkK6 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .label__1wkK6 {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__1wkK6 {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .label__1wkK6 {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .label__1wkK6 {
    margin-top: calc(2.1333333333vw);
  }
}

.standard-options__1wHbI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media only screen and (min-width: 450px) {
  .other-options__2-Gpf {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .other-options__2-Gpf {
    margin-top: calc(6.4vw);
  }
}

.other-option__1zb9m {
  white-space: normal !important;
  height: auto !important;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .other-option__1zb9m {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .other-option__1zb9m {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .other-option__1zb9m {
    font-size: calc(2.6666666667vw);
    line-height: calc(3.7333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .other-option__1zb9m {
    width: 355px;
    padding: 8px 24px;
    margin: 12px auto 0;
  }
}

@media only screen and (max-width: 449px) {
  .other-option__1zb9m {
    width: calc(74.6666666667vw);
    padding: calc(3.2vw) calc(6.4vw);
    margin: calc(3.2vw) auto 0;
  }
}

