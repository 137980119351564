@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.card_wrapper__2iUSP {
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .card_wrapper__2iUSP {
    margin-top: 16px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .card_wrapper__2iUSP {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 449px) {
  .card_wrapper__2iUSP {
    margin-top: calc(7.7333333333vw);
  }
}

.card__2ddEV {
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  color: #1f2c32;
  border-radius: 8px;
  -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

@media only screen and (min-width: 1200px) {
  .card__2ddEV {
    width: 330px;
    margin-right: 13.13px;
    margin-bottom: 13.13px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .card__2ddEV {
    width: 330px;
    margin-right: 18px;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 449px) {
  .card__2ddEV {
    width: 100%;
    margin-bottom: calc(3.5013333333vw);
  }
}

.reserve_label__149rH {
  background-color: #6b9aaa;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top-left-radius: 8px;
}

@media only screen and (min-width: 1200px) {
  .reserve_label__149rH {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .reserve_label__149rH {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .reserve_label__149rH {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .reserve_label__149rH {
    width: 76px;
    height: 36px;
  }
}

@media only screen and (max-width: 449px) {
  .reserve_label__149rH {
    width: calc(20.2666666667vw);
    height: calc(9.6vw);
  }
}

.thumbnail-wrapper__1AMbh {
  height: 165px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 449px) {
  .thumbnail-wrapper__1AMbh {
    height: calc(43.4666666667vw);
  }
}

.thumbnail__kbe9g {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 450px) {
  .main__Zyyp- {
    padding: 24px 24px;
  }
}

@media only screen and (max-width: 449px) {
  .main__Zyyp- {
    padding: calc(6.4vw) calc(6.4vw);
  }
}

@media only screen and (min-width: 450px) {
  .contents__3iEFj {
    margin: 24px 0;
  }
}

@media only screen and (max-width: 449px) {
  .contents__3iEFj {
    margin: calc(6.4vw) 0;
  }
}

.content-box__3RBON {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (min-width: 450px) {
  .content-box__3RBON {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 449px) {
  .content-box__3RBON {
    margin-bottom: calc(2.6666666667vw);
  }
}

.label__2Qali {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  color: #afb0b5;
}

@media only screen and (min-width: 1200px) {
  .label__2Qali {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .label__2Qali {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .label__2Qali {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .label__2Qali {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 449px) {
  .label__2Qali {
    margin-right: calc(6.4vw);
  }
}

.body__3-Qv8 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-weight: bold;
  line-height: 20px;
  color: #1f2c32;
}

@media only screen and (min-width: 1200px) {
  .body__3-Qv8 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .body__3-Qv8 {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .body__3-Qv8 {
    font-size: calc(3.2vw);
    line-height: calc(5.3333333333vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

.title__1g866 {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .title__1g866 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .title__1g866 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 2px;
  }
}

@media only screen and (max-width: 449px) {
  .title__1g866 {
    font-size: calc(4.2666666667vw);
    line-height: calc(6.4vw);
    letter-spacing: calc(0.5333333333vw);
  }
}

.thankyou__1SWRO {
  position: absolute;
  max-width: 170px;
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  bottom: 3%;
  right: 2%;
}

.thankyou__1SWRO.show_button__rQwBZ {
  bottom: 13%;
}

.button__2Z3O4.disabled__79F-1 {
  color: #d3d4d8;
  background: #f3f5f5;
}

