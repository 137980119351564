@charset "UTF-8";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
}

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.ButtonBase_4_7_0_1ijualg0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  font-weight: 600;
  letter-spacing: 0.03em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  border-radius: 999px;
  background-clip: padding-box;
  text-decoration: none;
  cursor: pointer;
}

.ButtonBase_4_7_0_1ijualg0:hover {
  text-decoration: none;
}

.ButtonBase_4_7_0_1ijualg1 {
  height: 48px;
  font-size: 14px;
  padding: 0 32px;
}

.ButtonBase_4_7_0_1ijualg2 {
  height: 36px;
  font-size: 12px;
  padding: 0 20px;
}

.ButtonBase_4_7_0_1ijualg3 {
  height: 30px;
  font-size: 12px;
  padding: 0 16px;
}

.ButtonBase_4_7_0_1ijualg4 {
  width: 22px;
  height: 22px;
}

.ButtonBase_4_7_0_1ijualg5 {
  width: 18px;
  height: 18px;
}

.ButtonBase_4_7_0_1ijualg6 {
  width: 14px;
  height: 14px;
}

.ButtonBase_4_7_0_1ijualg7 {
  padding: 0 32px 0 24px;
  gap: 8px;
}

.ButtonBase_4_7_0_1ijualg8 {
  padding: 0 20px 0 16px;
  gap: 6px;
}

.ButtonBase_4_7_0_1ijualg9 {
  padding: 0 16px 0 14px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualga {
  padding: 0 24px 0 32px;
  gap: 8px;
}

.ButtonBase_4_7_0_1ijualgb {
  padding: 0 16px 0 20px;
  gap: 6px;
}

.ButtonBase_4_7_0_1ijualgc {
  padding: 0 14px 0 16px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgd {
  padding: 0 28px 0 16px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualge {
  padding: 0 20px 0 14px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgf {
  padding: 0 18px 0 10px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgg {
  padding: 0 16px 0 28px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgh {
  padding: 0 12px 0 22px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgi {
  padding: 0 8px 0 18px;
  gap: 4px;
}

.ButtonBase_4_7_0_1ijualgj {
  background-color: #4c6072;
  color: #fff;
  border: 1px solid #4c6072;
}

.ButtonBase_4_7_0_1ijualgj:hover {
  background-color: #354350;
  border: 1px solid #354350;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgk {
  background-color: transparent;
  border: 1px solid #4c6072;
  color: #4c6072;
}

.ButtonBase_4_7_0_1ijualgk:hover {
  background-color: rgba(76, 96, 114, 0.1019607843);
  color: #4c6072;
}

.ButtonBase_4_7_0_1ijualgl {
  background-color: #df5475;
  border: 1px solid #df5475;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgl:hover {
  background-color: #c34160;
  border: 1px solid #C34160;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgm {
  background-color: transparent;
  color: #4c6072;
  border: none;
}

.ButtonBase_4_7_0_1ijualgm:hover {
  background-color: rgba(76, 96, 114, 0.1019607843);
  color: #4c6072;
}

.ButtonBase_4_7_0_1ijualgn {
  background-color: #99293d;
  color: #fff;
  border: none;
}

.ButtonBase_4_7_0_1ijualgn:hover {
  background-color: #99293d;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgo {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgo:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgp {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgp:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgq {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgq:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgr {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgr:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgs {
  background-color: transparent;
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgs:hover {
  background-color: rgba(255, 255, 255, 0.1019607843);
  border: 1px solid #FFFFFF;
  color: #fff;
}

.ButtonBase_4_7_0_1ijualgt {
  width: 100%;
}

.ButtonBase_4_7_0_1ijualgu {
  pointer-events: none;
  background-color: #e2e4e4;
  border: 1px solid #e2e4e4;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualgv {
  pointer-events: none;
  background-color: transparent;
  border: 1px solid #c7cbcc;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualgw {
  pointer-events: none;
  background-color: #e2e4e4;
  border: 1px solid #e2e4e4;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualgx {
  pointer-events: none;
  background-color: transparent;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualgy {
  pointer-events: none;
  background-color: #e2e4e4;
  border: 1px solid #e2e4e4;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualgz, .ButtonBase_4_7_0_1ijualg10, .ButtonBase_4_7_0_1ijualg11, .ButtonBase_4_7_0_1ijualg12, .ButtonBase_4_7_0_1ijualg13 {
  pointer-events: none;
  background-color: transparent;
  border: 1px solid #c7cbcc;
  color: #c7cbcc;
}

.ButtonBase_4_7_0_1ijualg14 {
  margin-left: -10px;
  margin-right: 10px;
}

.ButtonBase_4_7_0_1ijualg15 {
  margin-left: -5px;
  margin-right: 8px;
}

.ButtonBase_4_7_0_1ijualg16 {
  margin-left: -2px;
  margin-right: 6px;
}

.ButtonBase_4_7_0_1ijualg17 {
  margin-left: 0;
  margin-right: 4px;
}

.ButtonBase_4_7_0_1ijualg18 {
  margin-left: 0;
  margin-right: 2px;
}

.ButtonBase_4_7_0_1ijualg19, .ButtonBase_4_7_0_1ijualg1a {
  margin-left: 0;
  margin-right: 4px;
}

.ButtonBase_4_7_0_1ijualg1b {
  margin-left: 0;
  margin-right: 2px;
}

.ButtonBase_4_7_0_1ijualg1c {
  margin-left: 0;
  margin-right: 0;
}

.ButtonBase_4_7_0_1ijualg1d, .ButtonBase_4_7_0_1ijualg1e {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.ButtonBase_4_7_0_1ijualg1e > svg {
  width: 100%;
  height: 100%;
}

.Spinner_4_7_0_uldb1k0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Spinner_4_7_0_uldb1k1 {
  fill: #fff;
}

.Spinner_4_7_0_uldb1k2 {
  fill: #4c6072;
}

.Spinner_4_7_0_uldb1k3 {
  width: 22px;
  height: 22px;
}

.Spinner_4_7_0_uldb1k4 {
  width: 18px;
  height: 18px;
}

.Spinner_4_7_0_uldb1k5 {
  width: 14px;
  height: 14px;
}

.Heading_4_7_0_1n94fuz0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  line-height: 150%;
  letter-spacing: 0.03em;
  font-weight: 600;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.Heading_4_7_0_1n94fuz6 {
  font-size: var(--Heading_4_7_0_1n94fuz1);
}

.Heading_4_7_0_1n94fuzc {
  color: var(--Heading_4_7_0_1n94fuz7);
}

.Heading_4_7_0_1n94fuzi {
  text-align: var(--Heading_4_7_0_1n94fuzd);
}

@media screen and (max-width: 1280px) {
  .Heading_4_7_0_1n94fuz6 {
    font-size: var(--Heading_4_7_0_1n94fuz2);
  }

  .Heading_4_7_0_1n94fuzc {
    color: var(--Heading_4_7_0_1n94fuz8);
  }

  .Heading_4_7_0_1n94fuzi {
    text-align: var(--Heading_4_7_0_1n94fuze);
  }
}

@media screen and (max-width: 1012px) {
  .Heading_4_7_0_1n94fuz6 {
    font-size: var(--Heading_4_7_0_1n94fuz3);
  }

  .Heading_4_7_0_1n94fuzc {
    color: var(--Heading_4_7_0_1n94fuz9);
  }

  .Heading_4_7_0_1n94fuzi {
    text-align: var(--Heading_4_7_0_1n94fuzf);
  }
}

@media screen and (max-width: 768px) {
  .Heading_4_7_0_1n94fuz6 {
    font-size: var(--Heading_4_7_0_1n94fuz4);
  }

  .Heading_4_7_0_1n94fuzc {
    color: var(--Heading_4_7_0_1n94fuza);
  }

  .Heading_4_7_0_1n94fuzi {
    text-align: var(--Heading_4_7_0_1n94fuzg);
  }
}

@media screen and (max-width: 544px) {
  .Heading_4_7_0_1n94fuz6 {
    font-size: var(--Heading_4_7_0_1n94fuz5);
  }

  .Heading_4_7_0_1n94fuzc {
    color: var(--Heading_4_7_0_1n94fuzb);
  }

  .Heading_4_7_0_1n94fuzi {
    text-align: var(--Heading_4_7_0_1n94fuzh);
  }
}

.BodyText_4_7_0_10l14yx0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  letter-spacing: 0.03em;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.BodyText_4_7_0_10l14yx6 {
  font-size: var(--BodyText_4_7_0_10l14yx1);
}

.BodyText_4_7_0_10l14yxc {
  line-height: var(--BodyText_4_7_0_10l14yx7);
}

.BodyText_4_7_0_10l14yxi {
  color: var(--BodyText_4_7_0_10l14yxd);
}

.BodyText_4_7_0_10l14yxo {
  font-weight: var(--BodyText_4_7_0_10l14yxj);
}

.BodyText_4_7_0_10l14yxu {
  text-align: var(--BodyText_4_7_0_10l14yxp);
}

@media screen and (max-width: 1280px) {
  .BodyText_4_7_0_10l14yx6 {
    font-size: var(--BodyText_4_7_0_10l14yx2);
  }

  .BodyText_4_7_0_10l14yxc {
    line-height: var(--BodyText_4_7_0_10l14yx8);
  }

  .BodyText_4_7_0_10l14yxi {
    color: var(--BodyText_4_7_0_10l14yxe);
  }

  .BodyText_4_7_0_10l14yxo {
    font-weight: var(--BodyText_4_7_0_10l14yxk);
  }

  .BodyText_4_7_0_10l14yxu {
    text-align: var(--BodyText_4_7_0_10l14yxq);
  }
}

@media screen and (max-width: 1012px) {
  .BodyText_4_7_0_10l14yx6 {
    font-size: var(--BodyText_4_7_0_10l14yx3);
  }

  .BodyText_4_7_0_10l14yxc {
    line-height: var(--BodyText_4_7_0_10l14yx9);
  }

  .BodyText_4_7_0_10l14yxi {
    color: var(--BodyText_4_7_0_10l14yxf);
  }

  .BodyText_4_7_0_10l14yxo {
    font-weight: var(--BodyText_4_7_0_10l14yxl);
  }

  .BodyText_4_7_0_10l14yxu {
    text-align: var(--BodyText_4_7_0_10l14yxr);
  }
}

@media screen and (max-width: 768px) {
  .BodyText_4_7_0_10l14yx6 {
    font-size: var(--BodyText_4_7_0_10l14yx4);
  }

  .BodyText_4_7_0_10l14yxc {
    line-height: var(--BodyText_4_7_0_10l14yxa);
  }

  .BodyText_4_7_0_10l14yxi {
    color: var(--BodyText_4_7_0_10l14yxg);
  }

  .BodyText_4_7_0_10l14yxo {
    font-weight: var(--BodyText_4_7_0_10l14yxm);
  }

  .BodyText_4_7_0_10l14yxu {
    text-align: var(--BodyText_4_7_0_10l14yxs);
  }
}

@media screen and (max-width: 544px) {
  .BodyText_4_7_0_10l14yx6 {
    font-size: var(--BodyText_4_7_0_10l14yx5);
  }

  .BodyText_4_7_0_10l14yxc {
    line-height: var(--BodyText_4_7_0_10l14yxb);
  }

  .BodyText_4_7_0_10l14yxi {
    color: var(--BodyText_4_7_0_10l14yxh);
  }

  .BodyText_4_7_0_10l14yxo {
    font-weight: var(--BodyText_4_7_0_10l14yxn);
  }

  .BodyText_4_7_0_10l14yxu {
    text-align: var(--BodyText_4_7_0_10l14yxt);
  }
}

.LabelText_4_7_0_8f5ylw0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  line-height: 100%;
  letter-spacing: 0.03em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.LabelText_4_7_0_8f5ylw6 {
  font-size: var(--LabelText_4_7_0_8f5ylw1);
}

.LabelText_4_7_0_8f5ylwc {
  color: var(--LabelText_4_7_0_8f5ylw7);
}

.LabelText_4_7_0_8f5ylwi {
  font-weight: var(--LabelText_4_7_0_8f5ylwd);
}

@media screen and (max-width: 1280px) {
  .LabelText_4_7_0_8f5ylw6 {
    font-size: var(--LabelText_4_7_0_8f5ylw2);
  }

  .LabelText_4_7_0_8f5ylwc {
    color: var(--LabelText_4_7_0_8f5ylw8);
  }

  .LabelText_4_7_0_8f5ylwi {
    font-weight: var(--LabelText_4_7_0_8f5ylwe);
  }
}

@media screen and (max-width: 1012px) {
  .LabelText_4_7_0_8f5ylw6 {
    font-size: var(--LabelText_4_7_0_8f5ylw3);
  }

  .LabelText_4_7_0_8f5ylwc {
    color: var(--LabelText_4_7_0_8f5ylw9);
  }

  .LabelText_4_7_0_8f5ylwi {
    font-weight: var(--LabelText_4_7_0_8f5ylwf);
  }
}

@media screen and (max-width: 768px) {
  .LabelText_4_7_0_8f5ylw6 {
    font-size: var(--LabelText_4_7_0_8f5ylw4);
  }

  .LabelText_4_7_0_8f5ylwc {
    color: var(--LabelText_4_7_0_8f5ylwa);
  }

  .LabelText_4_7_0_8f5ylwi {
    font-weight: var(--LabelText_4_7_0_8f5ylwg);
  }
}

@media screen and (max-width: 544px) {
  .LabelText_4_7_0_8f5ylw6 {
    font-size: var(--LabelText_4_7_0_8f5ylw5);
  }

  .LabelText_4_7_0_8f5ylwc {
    color: var(--LabelText_4_7_0_8f5ylwb);
  }

  .LabelText_4_7_0_8f5ylwi {
    font-weight: var(--LabelText_4_7_0_8f5ylwh);
  }
}

.Alert_4_7_0_ttib1n0 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 16px;
  background-color: #fdf6f8;
  color: #df5475;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  border-radius: 3px;
  border: 1px #f2b9c6 solid;
}

.Alert_4_7_0_ttib1n1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Alert_4_7_0_ttib1n2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4px;
}

.Alert_4_7_0_ttib1n2 a {
  color: #df5475;
  text-decoration: underline;
}

.Icon_4_7_0_1wrg9wy0 svg {
  width: 100%;
  height: 100%;
}

.Icon_4_7_0_1wrg9wyb {
  width: var(--Icon_4_7_0_1wrg9wy1);
  height: var(--Icon_4_7_0_1wrg9wy6);
}

.Icon_4_7_0_1wrg9wyc svg {
  fill: #1f2d32;
}

.Icon_4_7_0_1wrg9wyd svg {
  fill: #4c6072;
}

.Icon_4_7_0_1wrg9wye svg {
  fill: #849194;
}

.Icon_4_7_0_1wrg9wyf svg {
  fill: #c7cbcc;
}

.Icon_4_7_0_1wrg9wyg svg {
  fill: #fff;
}

.Icon_4_7_0_1wrg9wyh svg {
  fill: #df5475;
}

.Icon_4_7_0_1wrg9wyi svg {
  fill: #c38383;
}

.Icon_4_7_0_1wrg9wyj svg {
  fill: #ba7d7d;
}

.Icon_4_7_0_1wrg9wyk svg {
  fill: #6b9baa;
}

.Icon_4_7_0_1wrg9wyl svg {
  fill: #4d8193;
}

.Icon_4_7_0_1wrg9wym svg {
  fill: #729897;
}

.Icon_4_7_0_1wrg9wyn svg {
  fill: #3e6770;
}

.Icon_4_7_0_1wrg9wyo svg {
  fill: inherit;
}

@media screen and (max-width: 1280px) {
  .Icon_4_7_0_1wrg9wyb {
    width: var(--Icon_4_7_0_1wrg9wy2);
    height: var(--Icon_4_7_0_1wrg9wy7);
  }
}

@media screen and (max-width: 1012px) {
  .Icon_4_7_0_1wrg9wyb {
    width: var(--Icon_4_7_0_1wrg9wy3);
    height: var(--Icon_4_7_0_1wrg9wy8);
  }
}

@media screen and (max-width: 768px) {
  .Icon_4_7_0_1wrg9wyb {
    width: var(--Icon_4_7_0_1wrg9wy4);
    height: var(--Icon_4_7_0_1wrg9wy9);
  }
}

@media screen and (max-width: 544px) {
  .Icon_4_7_0_1wrg9wyb {
    width: var(--Icon_4_7_0_1wrg9wy5);
    height: var(--Icon_4_7_0_1wrg9wya);
  }
}

.Callout_4_7_0_1lkve1o0 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  border-radius: 3px;
}

.Callout_4_7_0_1lkve1o1 {
  background-color: #f6f7f7;
  color: #4c6072;
  border: 1px #b6bec5 solid;
}

.Callout_4_7_0_1lkve1o2 {
  background-color: #fdf6f8;
  color: #df5475;
  border: 1px #f2b9c6 solid;
}

.Callout_4_7_0_1lkve1o3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Callout_4_7_0_1lkve1o4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 4px;
}

.Callout_4_7_0_1lkve1o4 a {
  color: inherit;
  text-decoration: underline;
}

.Callout_4_7_0_1lkve1o4 * {
  color: inherit;
}

.Callout_4_7_0_1lkve1o1 p {
  color: #1f2d32;
}

.OptionalLabel_4_7_0_3te9lh0 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2px 3px;
  gap: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 14px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.OptionalLabel_4_7_0_3te9lh1 {
  background: #849194;
  border: 1px solid #849194;
}

.OptionalLabel_4_7_0_3te9lh2 {
  background: #df5475;
  border: 1px solid #df5475;
}

.LabeledForm_4_7_0_1n5uyfm0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.LabeledForm_4_7_0_1n5uyfm1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.LabeledForm_4_7_0_1n5uyfm3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}

.LabeledForm_4_7_0_1n5uyfm4, .LabeledForm_4_7_0_1n5uyfm5 {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.LabeledForm_4_7_0_1n5uyfm6 {
  margin-top: 4px;
}

.LabeledForm_4_7_0_1n5uyfm7 {
  margin-top: 4px;
  padding-left: 216px;
}

.Tooltip_4_7_0_7h1l8p0 {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Tooltip_4_7_0_7h1l8p0:before {
  content: "";
  position: absolute;
  z-index: 1000001;
  width: 0px;
  height: 0px;
  color: #475357;
  pointer-events: none;
  border: 8px solid transparent;
  display: none;
}

.Tooltip_4_7_0_7h1l8p0:after {
  content: attr(aria-label);
  position: absolute;
  z-index: 1000000;
  padding: 2px 8px;
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: break-word;
  white-space: pre;
  pointer-events: none;
  background: #475357;
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1019607843);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1019607843);
  border-radius: 4px;
  display: none;
}

.Tooltip_4_7_0_7h1l8p0:hover:before {
  display: inline-block;
  text-decoration: none;
}

.Tooltip_4_7_0_7h1l8p0:hover:after {
  display: inline-block;
  text-decoration: none;
}

.Tooltip_4_7_0_7h1l8p1:after {
  right: 50%;
  bottom: 100%;
  margin-bottom: 12px;
  -webkit-transform: translate(50%);
          transform: translate(50%);
}

.Tooltip_4_7_0_7h1l8p1:before {
  top: -14px;
  right: 50%;
  bottom: auto;
  margin-right: -8px;
  border-top-color: #475357;
}

.Tooltip_4_7_0_7h1l8p2:after {
  right: auto;
  bottom: 100%;
  margin-bottom: 12px;
  left: 50%;
  margin-left: -18px;
}

.Tooltip_4_7_0_7h1l8p2:before {
  top: -14px;
  right: 50%;
  bottom: auto;
  margin-right: -8px;
  border-top-color: #475357;
}

.Tooltip_4_7_0_7h1l8p3:after {
  bottom: 50%;
  left: 100%;
  margin-left: 12px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.Tooltip_4_7_0_7h1l8p3:before {
  top: 50%;
  right: -14px;
  bottom: 50%;
  margin-top: -8px;
  border-right-color: #475357;
}

.Tooltip_4_7_0_7h1l8p4:after {
  top: 100%;
  right: auto;
  margin-top: 8px;
  left: 50%;
  margin-left: -18px;
}

.Tooltip_4_7_0_7h1l8p4:before {
  top: auto;
  right: 50%;
  bottom: -10px;
  margin-right: -10px;
  border-bottom-color: #475357;
}

.Tooltip_4_7_0_7h1l8p5:after {
  top: 100%;
  right: 50%;
  margin-top: 8px;
  -webkit-transform: translate(50%);
          transform: translate(50%);
}

.Tooltip_4_7_0_7h1l8p5:before {
  top: auto;
  right: 50%;
  bottom: -10px;
  margin-right: -10px;
  border-bottom-color: #475357;
}

.Tooltip_4_7_0_7h1l8p6:after {
  top: 100%;
  right: 50%;
  margin-top: 8px;
  margin-right: -18px;
}

.Tooltip_4_7_0_7h1l8p6:before {
  top: auto;
  right: 50%;
  bottom: -10px;
  margin-right: -10px;
  border-bottom-color: #475357;
}

.Tooltip_4_7_0_7h1l8p7:after {
  right: 100%;
  bottom: 50%;
  margin-right: 12px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.Tooltip_4_7_0_7h1l8p7:before {
  top: 50%;
  bottom: 50%;
  left: -14px;
  margin-top: -8px;
  border-left-color: #475357;
}

.Tooltip_4_7_0_7h1l8p8:after {
  right: 50%;
  bottom: 100%;
  margin-bottom: 12px;
  margin-right: -18px;
}

.Tooltip_4_7_0_7h1l8p8:before {
  top: -14px;
  right: 50%;
  bottom: auto;
  margin-right: -8px;
  border-top-color: #475357;
}

.TextInput_4_7_0_1vrk2t80 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: relative;
}

.TextInput_4_7_0_1vrk2t81 {
  font-size: 14px;
  color: #1f2d32;
  border-radius: 3px;
  padding: 13px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  width: 100%;
}

.TextInput_4_7_0_1vrk2t81::-webkit-input-placeholder {
  color: #849194;
}

.TextInput_4_7_0_1vrk2t81::-ms-input-placeholder {
  color: #849194;
}

.TextInput_4_7_0_1vrk2t81::placeholder {
  color: #849194;
}

.TextInput_4_7_0_1vrk2t82 {
  background-color: #fff;
  border: 1px solid #c7cbcc;
}

.TextInput_4_7_0_1vrk2t82:hover {
  border: 1px solid #4D8193;
}

.TextInput_4_7_0_1vrk2t82:focus {
  border: 2px solid #4D8193;
  padding: 12px 15px;
}

.TextInput_4_7_0_1vrk2t83 {
  color: #849194;
  border: 1px solid #edeeef;
}

.TextInput_4_7_0_1vrk2t84 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.TextInput_4_7_0_1vrk2t85 {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  pointer-events: none;
}

.TextInputGroup_4_7_0_exj39b0 {
  display: grid;
}

.TextInputGroup_4_7_0_exj39b6 {
  gap: var(--TextInputGroup_4_7_0_exj39b1);
}

@media screen and (max-width: 1280px) {
  .TextInputGroup_4_7_0_exj39b6 {
    gap: var(--TextInputGroup_4_7_0_exj39b2);
  }
}

@media screen and (max-width: 1012px) {
  .TextInputGroup_4_7_0_exj39b6 {
    gap: var(--TextInputGroup_4_7_0_exj39b3);
  }
}

@media screen and (max-width: 768px) {
  .TextInputGroup_4_7_0_exj39b6 {
    gap: var(--TextInputGroup_4_7_0_exj39b4);
  }
}

@media screen and (max-width: 544px) {
  .TextInputGroup_4_7_0_exj39b6 {
    gap: var(--TextInputGroup_4_7_0_exj39b5);
  }
}

.Textarea_4_7_0_1903ywp0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  font-weight: 400;
  font-size: 14px;
  color: #1f2d32;
  border-radius: 3px;
  padding: 13px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  width: 100%;
}

.Textarea_4_7_0_1903ywp0::-webkit-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  font-weight: 400;
  color: #849194;
}

.Textarea_4_7_0_1903ywp0::-ms-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  font-weight: 400;
  color: #849194;
}

.Textarea_4_7_0_1903ywp0::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  font-weight: 400;
  color: #849194;
}

.Textarea_4_7_0_1903ywp1 {
  background-color: #fff;
  border: 1px solid #c7cbcc;
}

.Textarea_4_7_0_1903ywp1:hover {
  border: 1px solid #4D8193;
}

.Textarea_4_7_0_1903ywp1:focus {
  border: 2px solid #4D8193;
  padding: 12px 15px;
}

.Textarea_4_7_0_1903ywp2 {
  color: #849194;
  border: 1px solid #edeeef;
}

.Textarea_4_7_0_1903ywp3 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.Textarea_4_7_0_1903ywp9 {
  min-height: var(--Textarea_4_7_0_1903ywp4);
}

@media screen and (max-width: 1280px) {
  .Textarea_4_7_0_1903ywp9 {
    min-height: var(--Textarea_4_7_0_1903ywp5);
  }
}

@media screen and (max-width: 1012px) {
  .Textarea_4_7_0_1903ywp9 {
    min-height: var(--Textarea_4_7_0_1903ywp6);
  }
}

@media screen and (max-width: 768px) {
  .Textarea_4_7_0_1903ywp9 {
    min-height: var(--Textarea_4_7_0_1903ywp7);
  }
}

@media screen and (max-width: 544px) {
  .Textarea_4_7_0_1903ywp9 {
    min-height: var(--Textarea_4_7_0_1903ywp8);
  }
}

.Select_4_7_0_ocrnn60 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: relative;
}

.Select_4_7_0_ocrnn61 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 14px;
  color: #1f2d32;
  border-radius: 3px;
  padding: 13px 48px 13px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  max-width: 100%;
}

.Select_4_7_0_ocrnn61::-webkit-input-placeholder {
  color: #849194;
}

.Select_4_7_0_ocrnn61::-ms-input-placeholder {
  color: #849194;
}

.Select_4_7_0_ocrnn61::placeholder {
  color: #849194;
}

.Select_4_7_0_ocrnn62 {
  color: #849194;
}

.Select_4_7_0_ocrnn63 {
  background-color: #fff;
  border: 1px solid #c7cbcc;
}

.Select_4_7_0_ocrnn63:hover {
  border: 1px solid #4D8193;
}

.Select_4_7_0_ocrnn63:focus {
  border: 2px solid #4D8193;
  padding: 12px 15px;
}

.Select_4_7_0_ocrnn64 {
  color: #849194;
  background-color: #f6f7f7;
  border: 1px solid #edeeef;
}

.Select_4_7_0_ocrnn65 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.Select_4_7_0_ocrnn66 {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  pointer-events: none;
}

.Frame_4_7_0_15dkir0 {
  display: grid;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Frame_4_7_0_15dkir1 {
  background-color: #fff;
  border: 1px solid #c7cbcc;
}

.Frame_4_7_0_15dkir1:hover {
  background-color: #fff;
  border: 1px solid #4D8193;
}

.Frame_4_7_0_15dkir2 {
  background-color: #fff;
  border: 2px solid #4D8193;
}

.Frame_4_7_0_15dkir3 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.Frame_4_7_0_15dkir4 {
  background-color: #f6f7f7;
  border: 1px solid #e2e4e4;
}

.Checkbox_4_7_0_1w69k4t0 {
  display: inline-grid;
}

.Checkbox_4_7_0_1w69k4t1 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
}

.Checkbox_4_7_0_1w69k4t1:hover {
  cursor: pointer;
}

.Checkbox_4_7_0_1w69k4t2 {
  padding: 12px 16px;
}

.Checkbox_4_7_0_1w69k4t3 {
  padding: 11px 15px;
}

.Checkbox_4_7_0_1w69k4t4:hover {
  cursor: default;
}

.Checkbox_4_7_0_1w69k4t5 {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  width: 16px;
  height: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #c7cbcc;
  border-radius: 3px;
}

label:hover > .Checkbox_4_7_0_1w69k4t5 {
  background-color: #fff;
  border: 1px solid #4D8193;
}

input[type=checkbox]:focus-visible + label > .Checkbox_4_7_0_1w69k4t5 {
  background-color: #fff;
  border: 2px solid #4D8193;
}

input[type=checkbox]:checked + label > .Checkbox_4_7_0_1w69k4t6 {
  background-color: #6b9baa;
  border: 1px solid #6B9BAA;
}

input[type=checkbox]:checked + label:hover > .Checkbox_4_7_0_1w69k4t6 {
  background-color: #4d8193;
  border: 1px solid #4D8193;
}

input[type=checkbox]:focus-visible:checked + label > .Checkbox_4_7_0_1w69k4t6 {
  background-color: #6b9baa;
  border: 2px solid #4D8193;
}

.Checkbox_4_7_0_1w69k4t7 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

label:hover > .Checkbox_4_7_0_1w69k4t7 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

input[type=checkbox]:focus-visible + label > .Checkbox_4_7_0_1w69k4t7 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.Checkbox_4_7_0_1w69k4t8 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

label:hover > .Checkbox_4_7_0_1w69k4t8 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

input[type=checkbox]:focus-visible + label > .Checkbox_4_7_0_1w69k4t8 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

input[type=checkbox]:checked + label > .Checkbox_4_7_0_1w69k4t8 {
  background-color: #c7cbcc;
  border: 1px solid #c7cbcc;
}

.Checkbox_4_7_0_1w69k4t9 {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.Checkbox_4_7_0_1w69k4ta {
  padding-left: 22px;
}

.Checkbox_4_7_0_1w69k4tb {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.CheckboxGroup_4_7_0_r79ipq0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.CheckboxGroup_4_7_0_r79ipq1 {
  display: grid;
}

.CheckboxGroup_4_7_0_r79ipq7 {
  gap: var(--CheckboxGroup_4_7_0_r79ipq2);
}

@media screen and (max-width: 1280px) {
  .CheckboxGroup_4_7_0_r79ipq7 {
    gap: var(--CheckboxGroup_4_7_0_r79ipq3);
  }
}

@media screen and (max-width: 1012px) {
  .CheckboxGroup_4_7_0_r79ipq7 {
    gap: var(--CheckboxGroup_4_7_0_r79ipq4);
  }
}

@media screen and (max-width: 768px) {
  .CheckboxGroup_4_7_0_r79ipq7 {
    gap: var(--CheckboxGroup_4_7_0_r79ipq5);
  }
}

@media screen and (max-width: 544px) {
  .CheckboxGroup_4_7_0_r79ipq7 {
    gap: var(--CheckboxGroup_4_7_0_r79ipq6);
  }
}

.Radio_4_7_0_nqx14u0 {
  display: inline-grid;
}

.Radio_4_7_0_nqx14u1 {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
}

.Radio_4_7_0_nqx14u1:hover {
  cursor: pointer;
}

.Radio_4_7_0_nqx14u2 {
  padding: 12px 16px;
}

.Radio_4_7_0_nqx14u3 {
  padding: 11px 15px;
}

.Radio_4_7_0_nqx14u4:hover {
  cursor: default;
}

.Radio_4_7_0_nqx14u5 {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 16px;
  height: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #c7cbcc;
  border-radius: 100%;
}

.Radio_4_7_0_nqx14u5:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100%;
}

label:hover > .Radio_4_7_0_nqx14u5 {
  border: 1px solid #4D8193;
}

input[type=radio]:focus-visible + label > .Radio_4_7_0_nqx14u5 {
  border: 2px solid #4D8193;
}

input[type=radio]:checked + label > .Radio_4_7_0_nqx14u5:before {
  background-color: #4d8193;
}

.Radio_4_7_0_nqx14u6 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

.Radio_4_7_0_nqx14u6:before {
  background-color: #fdf6f8;
}

label:hover > .Radio_4_7_0_nqx14u6 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

input[type=radio]:focus-visible + label > .Radio_4_7_0_nqx14u6 {
  background-color: #fdf6f8;
  border: 1px solid #df5475;
}

input[type=radio]:checked + label > .Radio_4_7_0_nqx14u6:before {
  background-color: #df5475;
}

.Radio_4_7_0_nqx14u7 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

.Radio_4_7_0_nqx14u7:before {
  background-color: #f6f7f7;
}

label:hover > .Radio_4_7_0_nqx14u7 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

input[type=radio]:focus-visible + label > .Radio_4_7_0_nqx14u7 {
  background-color: #f6f7f7;
  border: 1px solid #c7cbcc;
}

input[type=radio]:checked + label > .Radio_4_7_0_nqx14u7:before {
  background-color: #c7cbcc;
}

.Radio_4_7_0_nqx14u8 {
  padding-left: 22px;
}

.Radio_4_7_0_nqx14u9 {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.RadioGroup_4_7_0_sl6n1u0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.RadioGroup_4_7_0_sl6n1u1 {
  display: grid;
}

.RadioGroup_4_7_0_sl6n1u7 {
  gap: var(--RadioGroup_4_7_0_sl6n1u2);
}

@media screen and (max-width: 1280px) {
  .RadioGroup_4_7_0_sl6n1u7 {
    gap: var(--RadioGroup_4_7_0_sl6n1u3);
  }
}

@media screen and (max-width: 1012px) {
  .RadioGroup_4_7_0_sl6n1u7 {
    gap: var(--RadioGroup_4_7_0_sl6n1u4);
  }
}

@media screen and (max-width: 768px) {
  .RadioGroup_4_7_0_sl6n1u7 {
    gap: var(--RadioGroup_4_7_0_sl6n1u5);
  }
}

@media screen and (max-width: 544px) {
  .RadioGroup_4_7_0_sl6n1u7 {
    gap: var(--RadioGroup_4_7_0_sl6n1u6);
  }
}

.Link_4_7_0_1iqmcgo0 {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  letter-spacing: 0.03em;
  text-decoration: none;
  cursor: pointer;
}

.Link_4_7_0_1iqmcgo0:hover {
  text-decoration: underline;
}

.Link_4_7_0_1iqmcgo1 {
  font-size: 10px;
}

.Link_4_7_0_1iqmcgo2 {
  font-size: 12px;
}

.Link_4_7_0_1iqmcgo3 {
  font-size: 14px;
}

.Link_4_7_0_1iqmcgo4 {
  font-size: 16px;
}

.Link_4_7_0_1iqmcgo5 {
  color: #2b8ade;
}

.Link_4_7_0_1iqmcgo6 {
  color: #849194;
}

.Link_4_7_0_1iqmcgo7 {
  color: #fff;
}

.CloseButton_4_7_0_1yc9z7x0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 999px;
  cursor: pointer;
}

.CloseButton_4_7_0_1yc9z7x1 {
  border: 1px solid #4c6072;
}

.CloseButton_4_7_0_1yc9z7x2 {
  width: 48px;
  height: 48px;
}

.CloseButton_4_7_0_1yc9z7x3 {
  width: 56px;
  height: 56px;
}

.Overlay_4_7_0_1j7y8l00 {
  background-color: rgba(207, 215, 226, 0.7215686275);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1400;
}

.ModalContent_4_7_0_jexnx20 {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  z-index: 1400;
  background-color: #fff;
  border-radius: 8px;
  max-height: 95vh;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ModalContent_4_7_0_jexnx21 {
  max-width: 327px;
}

.ModalContent_4_7_0_jexnx22 {
  max-width: 560px;
}

.ModalContent_4_7_0_jexnx23 {
  max-width: 960px;
}

.Modal_4_7_0_1f9ygae0 {
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1400;
}

.Modal_4_7_0_1f9ygae1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.Modal_4_7_0_1f9ygae2 {
  position: relative;
  z-index: 1400;
}

.Modal_4_7_0_1f9ygae3 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1400;
  top: 0;
  left: 0;
}

.Modal_4_7_0_1f9ygae4 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 24px;
}

.ModalHeader_4_7_0_4okmdo0 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: inherit;
  padding: 2rem 1.5rem 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border-radius: 0 0 8px 8px;
  background-color: rgba(255, 255, 255, 0.9019607843);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 100;
}

.ModalCloseButton_4_7_0_1ozo5ag0 {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  outline: none;
}

.ModalBody_4_7_0_dthkmr0 {
  padding: 0 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.ModalFooter_4_7_0_jrca7z0 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: inherit;
  padding: 16px 24px 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  gap: 0.5rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0 0 8px 8px;
}

.ModalFooter_4_7_0_jrca7z1 {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.ModalFooter_4_7_0_jrca7z2 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ModalFooter_4_7_0_jrca7z3 {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.ModalFooter_4_7_0_jrca7z4 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.ModalFooter_4_7_0_jrca7z5 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.ModalFooter_4_7_0_jrca7z6 {
  background-color: rgba(255, 255, 255, 0.9019607843);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1400;
}

.Image_4_7_0_o7q5j90 {
  width: 100%;
  height: auto;
}

.ModalWithImage_4_7_0_vq52ei0 {
  padding-top: 1.5rem;
}

.Table_4_7_0_osozqi0 {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  letter-spacing: 0.03em;
  font-size: 14px;
  line-height: 150%;
  color: #1f2d32;
}

.Table_4_7_0_osozqi1 {
  border-bottom: 2px solid #edeeef;
}

.Table_4_7_0_osozqi2 {
  padding: 0.5rem;
}

.Table_4_7_0_osozqi3 {
  padding: 0.5rem;
  border-bottom: 1px solid #edeeef;
}

.Stack_4_7_0_hhgw510 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Stack_4_7_0_hhgw511 {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Stack_4_7_0_hhgw512 {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.Stack_4_7_0_hhgw513 {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.Stack_4_7_0_hhgw514 {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.Stack_4_7_0_hhgw515 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Stack_4_7_0_hhgw516 {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.Stack_4_7_0_hhgw517 {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.Stack_4_7_0_hhgw518 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.Stack_4_7_0_hhgw519 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Stack_4_7_0_hhgw51a {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.Stack_4_7_0_hhgw51b {
  gap: 0.25rem;
}

.Stack_4_7_0_hhgw51c {
  gap: 0.375rem;
}

.Stack_4_7_0_hhgw51d {
  gap: 0.5rem;
}

.Stack_4_7_0_hhgw51e {
  gap: 0.625rem;
}

.Stack_4_7_0_hhgw51f {
  gap: 0.75rem;
}

.Stack_4_7_0_hhgw51g {
  gap: 0.875rem;
}

.Stack_4_7_0_hhgw51h {
  gap: 1rem;
}

.Stack_4_7_0_hhgw51i {
  gap: 1.25rem;
}

.Stack_4_7_0_hhgw51j {
  gap: 1.5rem;
}

.Stack_4_7_0_hhgw51k {
  gap: 1.75rem;
}

.Stack_4_7_0_hhgw51l {
  gap: 2rem;
}

.Stack_4_7_0_hhgw51m {
  gap: 2.25rem;
}

.Stack_4_7_0_hhgw51n {
  gap: 2.5rem;
}

.Stack_4_7_0_hhgw51o {
  gap: 3rem;
}

.Stack_4_7_0_hhgw51p {
  gap: 3.5rem;
}

.Stack_4_7_0_hhgw51q {
  gap: 4rem;
}

.Stack_4_7_0_hhgw51r {
  gap: 5rem;
}

.Stack_4_7_0_hhgw51s {
  gap: 6rem;
}

.Stack_4_7_0_hhgw51t {
  gap: 0.125rem;
}

.Stack_4_7_0_hhgw51u {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.Stack_4_7_0_hhgw51v {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.DefinitionList_4_7_0_sctea40 {
  width: 100%;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  letter-spacing: 0.03em;
  font-size: 14px;
  line-height: 150%;
  color: #1f2d32;
}

.StatusLabel_4_7_0_mmfme30 {
  background-color: #f6f7f7;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 6px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN, Hiragino Sans, Meiryo, sans-serif, "Segoe UI Emoji";
  line-height: 1;
  white-space: nowrap;
}

.StatusLabel_4_7_0_mmfme36 {
  height: var(--StatusLabel_4_7_0_mmfme31);
}

.StatusLabel_4_7_0_mmfme37 {
  background-color: #4c6072;
}

.StatusLabel_4_7_0_mmfme38 {
  background-color: #df5475;
}

.StatusLabel_4_7_0_mmfme39 {
  background-color: #4aae42;
}

.StatusLabel_4_7_0_mmfme3a {
  background-color: #c38383;
}

.StatusLabel_4_7_0_mmfme3b {
  background-color: #6b9baa;
}

.StatusLabel_4_7_0_mmfme3c {
  background-color: #729897;
}

.StatusLabel_4_7_0_mmfme3d {
  background-color: #edeeef;
}

.StatusLabel_4_7_0_mmfme3e {
  background-color: #fceef1;
}

.StatusLabel_4_7_0_mmfme3f {
  background-color: #edf7ec;
}

.StatusLabel_4_7_0_mmfme3g {
  background-color: #f2e5e5;
}

.StatusLabel_4_7_0_mmfme3h {
  background-color: #dfeaed;
}

.StatusLabel_4_7_0_mmfme3i {
  background-color: #e6efed;
}

.StatusLabel_4_7_0_mmfme3o {
  font-size: var(--StatusLabel_4_7_0_mmfme3j);
}

.StatusLabel_4_7_0_mmfme3p, .StatusLabel_4_7_0_mmfme3q, .StatusLabel_4_7_0_mmfme3r, .StatusLabel_4_7_0_mmfme3s, .StatusLabel_4_7_0_mmfme3t, .StatusLabel_4_7_0_mmfme3u {
  color: #fff;
}

.StatusLabel_4_7_0_mmfme3v {
  color: #4c6072;
}

.StatusLabel_4_7_0_mmfme3w {
  color: #df5475;
}

.StatusLabel_4_7_0_mmfme3x {
  color: #4aae42;
}

.StatusLabel_4_7_0_mmfme3y {
  color: #c38383;
}

.StatusLabel_4_7_0_mmfme3z {
  color: #6b9baa;
}

.StatusLabel_4_7_0_mmfme310 {
  color: #729897;
}

.StatusLabel_4_7_0_mmfme31b {
  width: var(--StatusLabel_4_7_0_mmfme311);
  height: var(--StatusLabel_4_7_0_mmfme316);
}

.StatusLabel_4_7_0_mmfme31c {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 3px;
}

.StatusLabel_4_7_0_mmfme31c > svg {
  width: 100%;
  height: 100%;
}

.StatusLabel_4_7_0_mmfme31d, .StatusLabel_4_7_0_mmfme31e, .StatusLabel_4_7_0_mmfme31f, .StatusLabel_4_7_0_mmfme31g, .StatusLabel_4_7_0_mmfme31h, .StatusLabel_4_7_0_mmfme31i {
  color: #fff;
}

.StatusLabel_4_7_0_mmfme31j {
  color: #4c6072;
}

.StatusLabel_4_7_0_mmfme31k {
  color: #df5475;
}

.StatusLabel_4_7_0_mmfme31l {
  color: #4aae42;
}

.StatusLabel_4_7_0_mmfme31m {
  color: #c38383;
}

.StatusLabel_4_7_0_mmfme31n {
  color: #6b9baa;
}

.StatusLabel_4_7_0_mmfme31o {
  color: #729897;
}

.StatusLabel_4_7_0_mmfme31p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (max-width: 1280px) {
  .StatusLabel_4_7_0_mmfme36 {
    height: var(--StatusLabel_4_7_0_mmfme32);
  }

  .StatusLabel_4_7_0_mmfme3o {
    font-size: var(--StatusLabel_4_7_0_mmfme3k);
  }

  .StatusLabel_4_7_0_mmfme31b {
    width: var(--StatusLabel_4_7_0_mmfme312);
    height: var(--StatusLabel_4_7_0_mmfme317);
  }
}

@media screen and (max-width: 1012px) {
  .StatusLabel_4_7_0_mmfme36 {
    height: var(--StatusLabel_4_7_0_mmfme33);
  }

  .StatusLabel_4_7_0_mmfme3o {
    font-size: var(--StatusLabel_4_7_0_mmfme3l);
  }

  .StatusLabel_4_7_0_mmfme31b {
    width: var(--StatusLabel_4_7_0_mmfme313);
    height: var(--StatusLabel_4_7_0_mmfme318);
  }
}

@media screen and (max-width: 768px) {
  .StatusLabel_4_7_0_mmfme36 {
    height: var(--StatusLabel_4_7_0_mmfme34);
  }

  .StatusLabel_4_7_0_mmfme3o {
    font-size: var(--StatusLabel_4_7_0_mmfme3m);
  }

  .StatusLabel_4_7_0_mmfme31b {
    width: var(--StatusLabel_4_7_0_mmfme314);
    height: var(--StatusLabel_4_7_0_mmfme319);
  }
}

@media screen and (max-width: 544px) {
  .StatusLabel_4_7_0_mmfme36 {
    height: var(--StatusLabel_4_7_0_mmfme35);
  }

  .StatusLabel_4_7_0_mmfme3o {
    font-size: var(--StatusLabel_4_7_0_mmfme3n);
  }

  .StatusLabel_4_7_0_mmfme31b {
    width: var(--StatusLabel_4_7_0_mmfme315);
    height: var(--StatusLabel_4_7_0_mmfme31a);
  }
}

.Avatar_4_7_0_354w640 {
  background-color: #f6f7f7;
  border-radius: 999px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Avatar_4_7_0_354w641 {
  width: 100%;
}

.Avatar_4_7_0_354w64c {
  width: var(--Avatar_4_7_0_354w642);
  height: var(--Avatar_4_7_0_354w647);
}

@media screen and (max-width: 1280px) {
  .Avatar_4_7_0_354w64c {
    width: var(--Avatar_4_7_0_354w643);
    height: var(--Avatar_4_7_0_354w648);
  }
}

@media screen and (max-width: 1012px) {
  .Avatar_4_7_0_354w64c {
    width: var(--Avatar_4_7_0_354w644);
    height: var(--Avatar_4_7_0_354w649);
  }
}

@media screen and (max-width: 768px) {
  .Avatar_4_7_0_354w64c {
    width: var(--Avatar_4_7_0_354w645);
    height: var(--Avatar_4_7_0_354w64a);
  }
}

@media screen and (max-width: 544px) {
  .Avatar_4_7_0_354w64c {
    width: var(--Avatar_4_7_0_354w646);
    height: var(--Avatar_4_7_0_354w64b);
  }
}

.FormGroup_4_7_0_1p6s97x0 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.FormGroup_4_7_0_1p6s97x1 {
  display: grid;
}

.FormGroup_4_7_0_1p6s97x7 {
  gap: var(--FormGroup_4_7_0_1p6s97x2);
}

@media screen and (max-width: 1280px) {
  .FormGroup_4_7_0_1p6s97x7 {
    gap: var(--FormGroup_4_7_0_1p6s97x3);
  }
}

@media screen and (max-width: 1012px) {
  .FormGroup_4_7_0_1p6s97x7 {
    gap: var(--FormGroup_4_7_0_1p6s97x4);
  }
}

@media screen and (max-width: 768px) {
  .FormGroup_4_7_0_1p6s97x7 {
    gap: var(--FormGroup_4_7_0_1p6s97x5);
  }
}

@media screen and (max-width: 544px) {
  .FormGroup_4_7_0_1p6s97x7 {
    gap: var(--FormGroup_4_7_0_1p6s97x6);
  }
}

.Banner_4_7_0_1dw1u9x0 {
  padding: 1.5rem;
  color: #fff;
}

.Banner_4_7_0_1dw1u9x1 {
  background: #4c6072;
}

.Banner_4_7_0_1dw1u9x2 {
  background: #4aae42;
}

.Banner_4_7_0_1dw1u9x3 {
  background: #df5475;
}

.Banner_4_7_0_1dw1u9x4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.Banner_4_7_0_1dw1u9x5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Banner_4_7_0_1dw1u9x6 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.Banner_4_7_0_1dw1u9x7 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.25rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.Banner_4_7_0_1dw1u9x8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media screen and (max-width: 544px) {
  .Banner_4_7_0_1dw1u9x4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .Banner_4_7_0_1dw1u9x5 {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .Banner_4_7_0_1dw1u9x8 {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    width: 100%;
    max-width: 1021px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 450px) {
  .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 80px auto 0;
  }
}

.main {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

