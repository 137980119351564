@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.placeholder__2SOLW {
  margin-bottom: min(6.4vw, 24px);
}

.button__2hoKH {
  font-size: 10px;
  font-weight: bold;
  line-height: 30px;
  width: 197px;
  color: #4c6072;
  border: 1px solid #4c6072;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.03);
          box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.03);
  border-radius: 112px;
  text-align: center;
  cursor: pointer;
}

.button__2hoKH:hover {
  opacity: 0.7;
}

