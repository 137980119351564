@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.h2__3xi3j {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 2px;
  color: #000000;
  margin-top: 28px;
  margin-bottom: 15px;
}

.form-submit-button__39r5b {
  margin: 48px 0px;
}

.button__1TLOK {
  width: 204px;
}

@media only screen and (max-width: 449px) {
  .button__1TLOK {
    margin-top: calc(17.0666666667vw);
    width: 100%;
  }
}

.button__1TLOK:hover {
  opacity: 0.7;
}

.button__1TLOK.disabled__387dz {
  cursor: default;
  opacity: 0.5;
}

