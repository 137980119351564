@charset "UTF-8";

/* 
  ブレイクポイント図

                                    | -> 450px                  1199px  <- | 
                                    | @ tablet                             | 

                                    | -> 450px                             | -> 1200px
                                    | @ non-sp                             | @ desktop

        319px <- |         449x <- |             768px <- |      1199px <- | 
    @small-sp    |          @sp    |  @tablet-vertical    |       @ mobile |

 */

/*
  :Reactコンポーネント内でのデバイス判定

  ※ CSS側のブレイクポイントとずれているので修正の必要がある

  const calculateViewDevice = (): ViewDeviceV3 => {
    if (window.matchMedia('(min-width: 1200px)').matches) {
      return 'DESKTOP';
    } else if (window.matchMedia('(min-width: 465px)').matches) {
      return 'TABLET';
    } else {
      return 'SP';
    }
  };

*/

/**
 * Do not edit directly
 * Generated on Tue, 20 Jun 2023 05:36:44 GMT
 */

.select-wrap__bhsxE {
  position: relative;
  width: 100%;
  display: inline-block;
}

.select-wrap__bhsxE .icon__1p6fQ {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #4c6072;
  pointer-events: none;
  cursor: pointer;
}

.select__3KBW4 {
  width: 100%;
  outline: none;
  text-indent: 0.01px;
  text-overflow: "";
  background: #f3f5f5;
  vertical-align: middle;
  font-size: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, Helvetica, Arial, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
}

.select__3KBW4:focus {
  border: 1px solid #3c676e;
  background: white;
}

.select__3KBW4:hover {
  border: 1px solid #3c676e;
}

@media only screen and (min-width: 1200px) {
  .select__3KBW4 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
  .select__3KBW4 {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.6px;
  }
}

@media only screen and (max-width: 449px) {
  .select__3KBW4 {
    font-size: calc(3.7333333333vw);
    line-height: calc(7.4666666667vw);
    letter-spacing: calc(0.4266666667vw);
  }
}

@media only screen and (min-width: 450px) {
  .select__3KBW4 {
    height: 72px;
    border-radius: 8px;
    padding: 0 32px 0 16px;
  }
}

@media only screen and (max-width: 449px) {
  .select__3KBW4 {
    height: calc(14.9333333333vw);
    border-radius: calc(2.1333333333vw);
    padding: 0 calc(8.5333333333vw) 0 calc(4.2666666667vw);
  }
}

.placeholder__yecr4 {
  color: #d3d4d8;
}

